.el-drawer__header {
  font-size: 16px;
  padding: 10px 20px !important;
  margin-bottom: 10px !important;
  border-bottom: 1px solid #ededed;
}

* {
  margin: 0;
  padding: 0;
}

[class^='el-icon-'],
[class*=' el-icon-'] {
  font-size: 14px;
}

em,
i {
  font-style: normal;
}

.base-color {
  color: #1890ff;
}

.blue-text {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}

.wbba {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

/* 布局 */
.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex_wrap {
  flex-wrap: wrap;
}

.flex_row_y {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.justify_start {
  justify-content: flex-start;
}

.justify_end {
  justify-content: flex-end;
}

.justify_center {
  justify-content: center;
}

.justify_around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.align_center {
  align-items: center;
}

.flex_xc_yc {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 文本 */
.t_a_c {
  text-align: center;
}

.t_a_j {
  text-align: justify;
}

.t_a_r {
  text-align: right;
}

/* often */
.o_hidden {
  overflow: hidden;
}

.c_pointer {
  cursor: pointer;
}

.w_nowrap {
  white-space: nowrap;
}

.d_block {
  display: block;
}

.left {
  float: left;
}

.right {
  float: right;
}

.p_r {
  position: relative;
}

.p_a {
  position: absolute;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.text-hidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 字号 */
.f_s_12 {
  font-size: 12px;
}

.f_s_14 {
  font-size: 14px;
}

.f_s_15 {
  font-size: 15px;
}

.f_s_16 {
  font-size: 16px;
}

.f_s_18 {
  font-size: 18px;
}

.f_s_20 {
  font-size: 20px;
}

.f_s_22 {
  font-size: 22px;
}

.f_s_26 {
  font-size: 26px;
}

.f_s_38 {
  font-size: 38px;
}

/* 字重 */
.f_w_4 {
  font-weight: 400 !important;
}

.f_w_5 {
  font-weight: 500 !important;
}

.f_w_6 {
  font-weight: 600 !important;
}

.f_w_7 {
  font-weight: 700 !important;
}

/* 字色 */
/* primary */
.c_primary {
  color: #409eff;
}

/* Success */
.c_success {
  color: #67c23a;
}

/* Warning */
.c_warning {
  color: #e6a23c;
}

/* Danger */
.c_danger {
  color: #f56c6c;
}

/* Info */
.c_info {
  color: #909399;
}

/* 背景色 */
.bg_c_f {
  background-color: #fff !important;
}

.bg_c_f2 {
  background-color: #f2f2f2 !important;
}

.bg_c_f5 {
  background-color: #f5f5f5 !important;
}

// line-height
.line-h18 {
  line-height: 18px;
}

.line-h20 {
  line-height: 20px;
}

.line-h22 {
  line-height: 22px;
}

.line-h24 {
  line-height: 24px;
}

/* padding margin  */
.p5 {
  padding: 5px !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.p30 {
  padding: 30px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.m5 {
  margin: 5px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

/* 宽 高 */
.w100 {
  width: 100%;
}

.vh_100 {
  width: 100vh;
}

.w_100 {
  width: 100px !important;
}

.w_200 {
  width: 200px !important;
}

.w_300 {
  width: 300px !important;
}

.w_400 {
  width: 400px !important;
}

.w_500 {
  width: 500px !important;
}

.vh_100 {
  height: 100vh;
}

.h_100 {
  height: 100px !important;
}

.h_200 {
  height: 200px !important;
}

.h_300 {
  height: 300px !important;
}

.h_400 {
  height: 400px !important;
}

.h_500 {
  height: 500px !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.black {
  color: #000;
  font-family: 'PingFang Bold';
}

.gray {
  color: #666 !important;
  font-family: 'PingFang Bold';
}

.dark {
  color: #333 !important;
  font-family: 'PingFang Bold';
}

.green {
  color: #008000 !important;
  font-family: 'PingFang Bold';
}

.blue {
  color: #409eff !important;
  font-family: 'PingFang Bold';
}

.red {
  color: #ff4d4f !important;
  font-family: 'PingFang Bold';
}

.red-pink {
  color: #D9001B !important;
}

.page-link {
  color: #409eff;
  text-decoration-line: underline;
  cursor: pointer;
}

.no-underline {
  text-decoration: none;
}

.icon-y {
  background: #fdf6ec !important;
  border: 1px solid #ffce81 !important;
  color: #e9ac50 !important;
}

.icon-b {
  background: #bfdeff !important;
  color: #0084ff !important;
  border: 1px solid #1890ff !important;
}

.icon-r {
  background: #fef0f0 !important;
  color: #f89797 !important;
  border: 1px solid #fbc9c9 !important;
}

.page-link-noline {
  color: #409eff;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 表格必填 */
.el-table__header .cell.require-th::before {
  display: inline-block;
  content: '*';
  background: none;
  margin-right: 5px;
  vertical-align: top;
  color: #f56c6c;
}

.el-button--info {
  border: none !important;
}

.upload-file-color {
  color: #606266 !important;
}

.bold {
  font-family: 'PingFang Bold';
}

.el-select-dropdown__wrap {
  max-height: 355px !important;
}

::v-deep .el-button--small {
  font-size: 14px !important;
}
.split-table-header-row .cell .el-checkbox{
  display: none !important;
}
.text-pre-wrap{
  white-space: pre-wrap; /* 保留换行 */
  display: block; /* 让 span 变成块级元素，确保换行生效 */
  word-wrap: break-word; /* 处理长单词换行 */
}
@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































.upload_file {
  margin-top: 10px;
  .file_list {
    margin-top: 10px;
    .file_line {
      max-width: 480px;
      height: 32px;
      background: #f5f7fa;
      border-radius: 3px;
      border: 1px solid #ebeef5;
      line-height: 32px;
      font-size: 12px;
      color: #606266;
      position: relative;
      display: flex;
      justify-content: flex-start;
      margin-top: 5px;
      box-sizing: border-box;
      padding-right: 20px;
      span {
        margin-right: 15px;
      }
      .icon_file {
        font-size: 16px;
        color: #fa6400;
        position: absolute;
        left: 5px;
        top: 8px;
      }

      .file_name {
        padding-left: 30px;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .size {
        width: 85px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icon_success {
        color: #1989fa;
      }

      .icon_del {
        font-size: 16px;
        position: absolute;
        right: 5px;
        top: 8px;
        cursor: pointer;
      }
    }
  }
}
.upload-cont {
  ::v-deep.el-upload-dragger {
    width: 260px;
    height: 80px;
    .el-icon-upload {
      margin: 0;
      font-size: 32px;
    }
  }
}

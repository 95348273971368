@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































.upload-images {
  .tips {
    margin-bottom: 10px;
  }

  .el-row-upload-box {
    > div {
      .upload-images {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}
